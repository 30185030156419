<template>
  <div>
    <div :class="result.table != null ? 'search-containerResult' : 'search-container'">
      <input class="searchstyle" v-model="searchQuery" type="text" id="search" placeholder="Enter passcode" />
      <button class="export-button" @click="searchByID">Submit</button>
    </div>

    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

    <div v-if="result">
      <!-- Filters and Export Button -->
      <div class="filters-container">
        <div v-if="isCommittee" class="filter-group">
          <label for="centreFilter">Center:</label>
          <select v-model="selectedCentre" @change="filterResults">
            <option value="">All</option>
            <option v-for="centre in uniqueCentres" :key="centre.CentreID" :value="centre.CentreName">
              {{ centre.CentreName }}
            </option>
          </select>
        </div>

        <div v-if="isCommittee" class="filter-group">
          <label for="areaMinistryFilter">Area/Ministry:</label>
          <select v-model="selectedAreaMinistry" @change="filterResults">
            <option value="">All</option>
            <option v-for="area in uniqueAreaMinistries" :key="area.AreaMinistryID" :value="area.AreaMinistryName">
              {{ area.AreaMinistryName }}
            </option>
          </select>
        </div>

        <div v-if="isAreaMinistry || isCommittee" class="filter-group">
          <label for="cgGroupFilter">Connect Group:</label>
          <select v-model="selectedCGGroup" @change="filterResults">
            <option value="">All</option>
            <option v-for="cgGroup in filteredCGGroups" :key="cgGroup.CGID" :value="cgGroup.CGName">
              {{ cgGroup.CGName }}
            </option>
          </select>
        </div>

        <div v-if="isAreaMinistry || isCommittee || isCGGroup" class="filter-group">
          <label for="inviteFilter">2024 Successful Invite:</label>
          <select v-model="selectedInvite2024" @change="filterResults">
            <option value="">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div v-if="isAreaMinistry || isCommittee || isCGGroup" class="filter-group">
          <label for="inviteFilter">2025 Successful Invite:</label>
          <select v-model="selectedInvite2025" @change="filterResults">
            <option value="">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <div v-if="isAreaMinistry || isCommittee || isCGGroup" class="filter-group">
          <label for="inviteFilter">Decision:</label>
          <select v-model="selectedDecision" @change="filterResults">
            <option value="">All</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </select>
        </div>

        <button @click="exportToExcel" class="export-button" v-if="isCommittee || isAreaMinistry">Export</button>
      </div>

      <div v-if="filteredResults.length && (isCGGroup || isAreaMinistry || isCommittee)">
        <!-- Counters -->
        <div class="counters-container">
          <div>
            <p>{{ formatNumber(totalEntries) }} Names Submitted</p>
            <p>{{ formatNumber(totalDecision) }} Decisions</p>
          </div>

          <div>
            <p>{{ formatNumber(successfulInvite2025) }} Successful Invites in 2025</p>
            <p>{{ formatNumber(successfulInvite2024) }} Successful Invites in 2024</p>
        </div>
          

        </div>
      </div>



      <ConfirmationModal :visible="showModal" :friendName="itemNameToDelete" @confirm="handleConfirmDelete"
        @cancel="handleCancelDelete" />
      <!-- Table -->
      <div class="table-wrapper">
        <table v-if="(filteredResults.length && (isCGGroup || isAreaMinistry || isCommittee)) || result.table"
          class="centered-table" id="combinedTable">
          <thead>
            <tr>
              <th v-if="isCommittee" @click="sortTable('CentreName')">
                Center
                <span class="sort-icon"
                  :class="{ 'asc': sortColumn === 'CentreName' && sortDirection === 'asc', 'desc': sortColumn === 'CentreName' && sortDirection === 'desc' }"></span>
              </th>
              <th v-if="isCommittee" @click="sortTable('AreaMinistryName')">
                Area/Ministry
                <span class="sort-icon"
                  :class="{ 'asc': sortColumn === 'AreaMinistryName' && sortDirection === 'asc', 'desc': sortColumn === 'AreaMinistryName' && sortDirection === 'desc' }"></span>
              </th>
              <th v-if="isCommittee || isAreaMinistry" @click="sortTable('CGName')">
                Connect Group
                <span class="sort-icon"
                  :class="{ 'asc': sortColumn === 'CGName' && sortDirection === 'asc', 'desc': sortColumn === 'CGName' && sortDirection === 'desc' }"></span>
              </th>
              <th @click="sortTable('InviterName')">
                Trinitarian
                <span class="sort-icon"
                  :class="{ 'asc': sortColumn === 'InviterName' && sortDirection === 'asc', 'desc': sortColumn === 'InviterName' && sortDirection === 'desc' }"></span>
              </th>
              <th @click="sortTable('FullName')">
                Family's/Friend's Name
                <span class="sort-icon"
                  :class="{ 'asc': sortColumn === 'FullName' && sortDirection === 'asc', 'desc': sortColumn === 'FullName' && sortDirection === 'desc' }"></span>
              </th>
              <th>
                2024 Successful Invite
              </th>
              <th>
                2025 Successful Invite
              </th>
              <th>
                Decision
              </th>
              <th>Actions</th> <!-- Actions column -->
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortedResults" :key="item.ID">
              <td v-if="isCommittee">{{ getCentreAbbreviation(item.CentreName) || '-' }}</td>
              <td v-if="isCommittee">{{ item.AreaMinistryName || '-' }}</td>
              <td v-if="isCommittee || isAreaMinistry">{{ item.CGName || '-' }}</td>
              <td>{{ item.InviterName }}</td>
              <td>{{ item.FullName }}</td>
              <td class="centered">
                <input type="checkbox" :checked="item.Invite2024 === 1" :disabled="isCommittee"
                  @change="updateInvite2024Status(item, $event.target.checked)" />
              </td>
              <td class="centered">
                <input type="checkbox" :checked="item.Invite2025 === 1" :disabled="isCommittee"
                  @change="updateInvite2025Status(item, $event.target.checked)" />
              </td>
              <td class="centered" @click="toggleDecision(item)">
                <span v-if="item.Decision === 1" class="star">★</span>
                <span v-else class="star empty">☆</span>
              </td>
              <td class="centered">
                <button class='body1' @click="openConfirmDelete(item)">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


    </div>
  </div>
</template>

<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import ConfirmationModal from './ConfirmationModal.vue';

export default {
  name: 'PageTwo',
  components: {
    ConfirmationModal
  },
  data() {
    return {
      combinedResults: [],
      searchQuery: '',
      result: {},
      selectedAreaMinistry: '',
      selectedCGGroup: '',
      selectedCentre: '',
      selectedInvite2024: '',
      uniqueAreaMinistries: [],
      uniqueCGGroups: [],
      uniqueCentres: [],
      selectedInvite2025: '',
      selectedDecision: '',
      showModal: false,
      itemToDelete: null, // Track the item to delete
      itemNameToDelete: '',
      sortColumn: '',     // Track the current sort column
      sortDirection: '', // Track the current sort direction (ascending or descending)
      centreMapping: {
        'Trinity Paya Lebar': 'PL',
        'Trinity Adam': 'AR',
        'Trinity Choa Chu Kang': 'CCK',
        // Add more mappings as needed
      },
      errorMessage: '', // Add this for the error message
    };
  },
  mounted() {
    this.fetchAreas();
    this.fetchCGGroups();
    this.fetchCentres();
  },
  computed: {

    isCommittee() {
      return this.result.table === 'Committee';
    },
    isCGGroup() {
      return this.result.table === 'CGTable';
    },
    isAreaMinistry() {
      return this.result.table === 'AreaMinistry';
    },
    filteredResults() {
      let results = this.combinedResults;

      if (this.selectedCentre) {
        results = results.filter(item => item.CentreName === this.selectedCentre);
      }

      if (this.selectedAreaMinistry) {
        results = results.filter(item => item.AreaMinistryName === this.selectedAreaMinistry);
      }

      if (this.selectedCGGroup) {
        results = results.filter(item => item.CGName === this.selectedCGGroup);
      }

      if (this.selectedInvite2024 !== '') {
        results = results.filter(item => item.Invite2024 === parseInt(this.selectedInvite2024));
      }

      if (this.selectedInvite2025 !== '') {
        results = results.filter(item => item.Invite2025 === parseInt(this.selectedInvite2025));
      }
      if (this.selectedDecision !== '') {
        results = results.filter(item => item.Decision === parseInt(this.selectedDecision));
      }

      return results;
    },
    filteredCGGroups() {
      if (!this.isAreaMinistry) {
        return this.uniqueCGGroups;
      }
      // Filter CG groups based on the selected area if viewing area ministry results
      return this.uniqueCGGroups.filter(cgGroup =>
        cgGroup.AreaMinistryName === this.selectedAreaMinistry
      );
    },
    totalEntries() {
      return this.filteredResults.length;
    },
    totalDecision(){
      return this.filteredResults.filter(item => item.Decision === 1).length;
    },
    successfulInvite2024() {
      return this.filteredResults.filter(item => item.Invite2024 === 1).length;
    },
    successfulInvite2025() {
      return this.filteredResults.filter(item => item.Invite2025 === 1).length;
    },
    sortedResults() {
      const sorted = [...this.filteredResults];

      if (this.sortColumn) {
        sorted.sort((a, b) => {
          const aValue = a[this.sortColumn] || '';
          const bValue = b[this.sortColumn] || '';

          // Treat empty strings as the highest values in descending order
          if (this.sortDirection === 'desc') {
            if (aValue === '' && bValue !== '') return -1;
            if (aValue !== '' && bValue === '') return 1;
          } else {
            // Ascending order: handle '-' and empty values
            if (aValue === '' && bValue !== '') return 1;
            if (aValue !== '' && bValue === '') return -1;
          }

          // Handle '-' and null values: treat them as the lowest values
          if (aValue === '-' && bValue !== '-') return 1;
          if (aValue !== '-' && bValue === '-') return -1;
          if (aValue === null && bValue !== null) return 1;
          if (aValue !== null && bValue === null) return -1;

          // Perform localeCompare with numeric option for other values
          return this.sortDirection === 'asc'
            ? aValue.localeCompare(bValue, undefined, { numeric: true })
            : bValue.localeCompare(aValue, undefined, { numeric: true });
        });
      }

      return sorted;
    }
  },
  methods: {
    async searchByID() {
      this.errorMessage = ''; // Reset error message before each search

      if (!this.searchQuery.trim()) {
        return;
      }
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/search', {
          params: { query: this.searchQuery }
        });
        const data = response.data;

        // Reset filters before setting new results
        this.resetFilters();
        this.resetSorting();

        // Sort results by ID in descending order
        if (data.records != null) {
          this.combinedResults = data.records.sort((a, b) => b.ID - a.ID);
          this.result = { table: data.table, records: data.records };
        } else if (data.table != null) {
          this.result = { table: data.table, records: null };
        } else {
          this.result = { table: null, records: null };
          this.errorMessage = 'Invalid Code – Please try again';
        }

        if (this.isAreaMinistry) {
          // Set selected area based on the results
          this.selectedAreaMinistry = data.records.length > 0 ? data.records[0].AreaMinistryName : '';
          this.updateFilteredCGGroups();  // Update CG groups based on the selected area
        }

        if(this.isCommittee){
          this.selectedCentre = 'Trinity Paya Lebar';
        }
      } catch (error) {
        console.error('Error searching:', error);
        this.result = { message: 'Error occurred while searching.' };
      }
    },
    async fetchAreas() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/areaministry');
        this.uniqueAreaMinistries = response.data;
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    },
    async fetchCGGroups() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/cggroupsfilter');
        this.uniqueCGGroups = response.data;
      } catch (error) {
        console.error('Error fetching CG groups:', error);
      }
    },
    async fetchCentres() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/centres');
        this.uniqueCentres = response.data;
      } catch (error) {
        console.error('Error fetching centres:', error);
      }
    },
    async updateInvite2024Status(item, isChecked) {
      const inviteStatus = isChecked ? 1 : 0;

      try {
        await axios.post('https://prayerwall.trinity.sg/api/updateInvite2024', {
          id: item.ID,
          invite: inviteStatus
        });

        const updatedItem = this.combinedResults.find(g => g.ID === item.ID);
        if (updatedItem) updatedItem.Invite2024 = inviteStatus;
      } catch (error) {
        console.error('Error updating invite status:', error);
      }
    },
    async updateInvite2025Status(item, isChecked) {
      const inviteStatus = isChecked ? 1 : 0;

      try {
        await axios.post('https://prayerwall.trinity.sg/api/updateInvite2025', {
          id: item.ID,
          invite: inviteStatus
        });

        const updatedItem = this.combinedResults.find(g => g.ID === item.ID);
        if (updatedItem) updatedItem.Invite2025 = inviteStatus;
      } catch (error) {
        console.error('Error updating invite status:', error);
      }
    },
    async toggleDecision(item) {
      if (this.isCommittee) return; // Prevent clicking if disabled

      const newStatus = item.Decision === 1 ? 0 : 1;

      try {
        await axios.post('https://prayerwall.trinity.sg/api/updateDecision', {
          id: item.ID,
          invite: newStatus
        });

        // Update locally after successful API call
        item.Decision = newStatus;
      } catch (error) {
        console.error('Error updating invite status:', error);
      }
    },
    async exportToExcel() {
      // Define the columns you want to include in the export
      let columns;
      if(this.isCommittee){
        columns = ['Centre Name', 'Area/Ministry Name', 'Connect Group Name', 'Trinitarian Name', 'Friend\'s Name', 'Successful Invite in 2024', 'Successful Invite in 2025', 'Decision','Date And Time'];
      }else{
        columns = ['Area/Ministry Name', 'Connect Group Name', 'Trinitarian Name', 'Friend\'s Name', 'Successful Invite in 2024', 'Successful Invite in 2025', 'Decision', 'Date And Time'];

      }
      // Function to format datetime in Singapore timezone as MMDDYYYY HH:MM:SS
      var formatDateTime = (datetime) => {
        if (!datetime) return '-';

        const date = new Date(datetime);

        // Format MMDDYYYY
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${month}/${day}/${year}`;

        // Format HH:MM:SS
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const formattedTime = `${hours}:${minutes}:${seconds}`;

        return `${formattedDate} ${formattedTime}`;
      };

      var transformedResults;
      // Transform data to include only the specified columns and format the "Successful Invite" column
      if (this.isCommittee) {
        transformedResults = this.filteredResults.map(item => ({
          'Centre Name': item.CentreName || '-',
          'Area/Ministry Name': item.AreaMinistryName || '-',
          'Connect Group Name': item.CGName || '-',
          'Trinitarian Name': item.InviterName || '-',
          'Friend\'s Name': item.FullName || '-',
          'Successful Invite in 2024': item.Invite2024 === 1 ? 'Yes' : 'No',  // Convert checkbox value to 'Yes' or 'No'
          'Successful Invite in 2025': item.Invite2025 === 1 ? 'Yes' : 'No',  // Convert checkbox value to 'Yes' or 'No'
          'Decision': item.Decision === 1 ? 'Yes' : 'No',  // Convert checkbox value to 'Yes' or 'No'
          'Date And Time': formatDateTime(item.CreatedDateTime) // Format and include the datetime
        }));
      }else{
        transformedResults = this.filteredResults.map(item => ({
          'Area/Ministry Name': item.AreaMinistryName || '-',
          'Connect Group Name': item.CGName || '-',
          'Trinitarian Name': item.InviterName || '-',
          'Friend\'s Name': item.FullName || '-',
          'Successful Invite in 2024': item.Invite2024 === 1 ? 'Yes' : 'No',  // Convert checkbox value to 'Yes' or 'No'
          'Successful Invite in 2025': item.Invite2025 === 1 ? 'Yes' : 'No',
          'Decision': item.Decision === 1 ? 'Yes' : 'No',
          'Date And Time': formatDateTime(item.CreatedDateTime) // Format and include the datetime
        }));
      }

      // Create worksheet from transformed data with specified columns
      const ws = XLSX.utils.json_to_sheet(transformedResults, { header: columns });

      // Create new workbook and add worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      // Generate Singapore date for timestamp
      const now = new Date();
      const options = {
        timeZone: 'Asia/Singapore',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      const formatter = new Intl.DateTimeFormat('en-SG', options);
      const parts = formatter.formatToParts(now);
      const dateParts = parts.reduce((acc, { type, value }) => {
        if (type === 'day') acc.push(value);
        if (type === 'month') acc.push(value);
        if (type === 'year') acc.push(value);
        return acc;
      }, []);
      const formattedDate = dateParts.join(''); // Combine parts without separators

      // Write to file with timestamp
      XLSX.writeFile(wb, `LoveSoulsRelentlessly_${formattedDate}.xlsx`);
    },
    filterResults() {
      // Trigger reactivity for filtered results
      this.filteredResults;
    },
    resetFilters() {
      this.selectedAreaMinistry = '';
      this.selectedCGGroup = '';
      this.selectedCentre = '';
      this.selectedInvite2024 = '';
      this.selectedInvite2025 = '';
      this.selectedDecision = '';
      this.updateFilteredCGGroups();
    },
    async updateFilteredCGGroups() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/cggroupsfilter');
        const allCGGroups = response.data;

        if (this.selectedAreaMinistry) {
          // Filter CG groups that are related to the selected area
          this.uniqueCGGroups = allCGGroups.filter(cgGroup =>
            cgGroup.AreaMinistryName === this.selectedAreaMinistry
          );
        } else {
          // If no area is selected, show all CG groups
          this.uniqueCGGroups = allCGGroups;
        }
      } catch (error) {
        console.error('Error fetching CG groups:', error);
      }
    },
    openConfirmDelete(item) {
      this.itemToDelete = item.ID;
      this.itemNameToDelete = item.FullName
      this.showModal = true;
    },
    async handleConfirmDelete() {
      if (this.itemToDelete) {
        try {
          await axios.delete(`https://prayerwall.trinity.sg/api/delete/${this.itemToDelete}`);
          this.combinedResults = this.combinedResults.filter(item => item.ID !== this.itemToDelete);
          this.filterResults();
        } catch (error) {
          console.error('Error deleting item:', error);
        }
        this.itemToDelete = null;
        this.itemNameToDelete = ''; // Clear the friend's name
        this.showModal = false;
      }
    },
    handleCancelDelete() {
      this.showModal = false;
      this.itemToDelete = null;
    },
    sortTable(column) {
      if (this.sortColumn === column) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortColumn = column;
        this.sortDirection = 'asc';
      }
    },
    // Convert full centre name to abbreviation
    getCentreAbbreviation(name) {
      return this.centreMapping[name] || name; // Default to the full name if no abbreviation found
    },
    resetSorting() {
      this.sortColumn = ''; // Reset to default column
      this.sortDirection = ''; // Reset to default direction
    },
    formatNumber(value) {
      return value.toLocaleString(); // Adds commas for thousands
    }
  },
  watch: {
    selectedAreaMinistry() {
      this.updateFilteredCGGroups();
    }
  }
}
</script>

<style scoped>
 .centered {
    text-align: center;
  }

  .centered input[type="checkbox"],
  .centered button {
    display: block;
    margin: auto;
  }

  .centered span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

.hidden-input {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

.star {
  font-size: 24px;
  cursor: pointer;
  color: gold;
}

.empty {
  color: gray;
}

::placeholder {
  color: #C2D2FF;
  opacity: 1; /* Firefox */
  font-weight: normal;
}
.error-message {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  color: #803BF7;
  margin-top: 8px;
}

@font-face {
  font-family: 'Acumin Pro Wide';
  src: url('../assets/Acumin Pro Wide Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

.sort-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  text-align: center;
  line-height: 12px;
  font-size: 14px;
  cursor: pointer;
}

.sort-icon.asc::before {
  content: '↑';
  /* Up arrow */
}

.sort-icon.desc::before {
  content: '↓';
  /* Down arrow */
}

.search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 15%;
}

.search-containerResult {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 50px;
}

.searchstyle {
  font-family: Acumin Pro Wide;
  font-size: 18px;
  /* Match font size */
  font-style: normal;
  font-weight: bold;
  line-height: 1.2;
  /* Adjust line-height to a unitless value */
  padding: 8px 15px;
  /* Match padding */
  background-color: #803BF7;
  /* Match background color */
  border: 1px solid #803BF7;
  /* Remove border */
  border-radius: 15px;
  /* Match border radius */
  cursor: pointer;
  /* Match cursor style */
  margin-top: 10px;
  /* Match top margin */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.search-container label,
.search-container input,
.search-container button {
  margin: 0;
}

.search-container input,
.search-containerResult input {
  color: white;
}

.search-containerResult label,
.search-containerResult input,
.search-containerResult button {
  margin: 0;
}

.filters-container select {
  width: 120px;
  /* Ensure uniform width for search input and dropdowns */
}

.search-container input,
.search-containerResult input {
  width: 150px;
}

.filters-container {
  display: flex;
  flex-direction: column;
  /* Stack filters vertically on mobile */
  align-items: center;
  margin-top: 12px;
  /* Center items horizontally */
  gap: 10px;
  /* Space between filter groups and export button */
  /* Remove any extra margin */
  padding: 0;
  /* Remove any extra padding */
}

.filter-group {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* Align items to center vertically */
  gap: 5px;
  /* Space between label and select */
  margin: 0;
  /* Remove any extra margin */
  padding: 0;
  /* Remove any extra padding */
  color: #803BF7;

}

.filter-group select {
  font-family: 'Acumin Pro Wide', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  flex: 1;
  /* Allow select to take remaining space */
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-sizing: border-box;
}


.export-button {
  font-family: Acumin Pro Wide;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  padding: 8px 15px;
  background-color: #A0F2C9;
  color: #803BF7;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  margin-top: 2px;
  /* Space above the export button */
}

.table-wrapper {
  overflow-x: auto;
  margin: 20px 0;
  justify-content: center;
  display: flex;

}

.centered-table {
  border-collapse: collapse;
  width: 80%;
  border: 1px solid #37008A;
  /* Rounds the corners of the table */
  overflow: hidden;
  /* Ensures content does not overflow out of rounded corners */
}

.centered-table th {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  border: 1px solid #37008A;
  color: #A0F2C9;
  padding: 8px;
  text-align: left;
}

.centered-table td {
  font-family: Acumin Pro Wide;
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  border: 1px solid #37008A;
  padding: 8px;
  text-align: left;
  background-color: #A0F2C9;
}

.centered-table th {
  background-color: #803BF7;
}

.counters-container {
  font-family: Acumin Pro Wide;
  font-size: 18px;
  font-style: italic;
  font-weight: medium;
  line-height: 120%;
  color: #803BF7;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  margin-top: 8px;
}

.body1 {
  font-family: Acumin Pro Wide;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  background-color: #803BF7;
  border: 1px solid #803BF7;
  color: #A0F2C9;
  border-radius: 15px;
  padding: 4px 8px;
  display: inline-flex;  /* Makes the button a flex container */
  align-items: center;   /* Vertically centers text */
  justify-content: center;  /* Horizontally centers text */
  text-align: center;    /* Ensures text alignment inside */
}

@media (max-width: 767px) {
  .search-container {
    padding-top: 50%;
  }

  .search-containerResult {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .table-wrapper {
    display: block;
  }

  .centered-table {
    width: auto;
    /* Allows horizontal scroll on small screens */
    min-width: 60%;
    /* Keeps table content from shrinking too much */
  }

  .centered-table th {
    font-family: Acumin Pro Wide;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    padding: 8px;
    text-align: left;
  }

  .centered-table td {
    font-family: Acumin Pro Wide;
    font-size: 13px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    padding: 8px;
    text-align: left;
  }

  .body1 {
    font-family: Acumin Pro Wide;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    display: inline-flex;  /* Makes the button a flex container */
  align-items: center;   /* Vertically centers text */
  justify-content: center;  /* Horizontally centers text */
  text-align: center;    /* Ensures text alignment inside */
  }

  .filters-container {
    display: grid;
    grid-template-columns: 1fr;
    /* Single column layout */
    justify-content: center;
    /* Center the grid within its container */
    gap: 10px;
    /* Space between grid items */
    width: 100%;
    /* Ensure filters container takes full width */
    max-width: 200px;
    /* Optional: Set a max-width to prevent excessive stretching */
    margin: 0 auto;
    /* Center the filters container */
  }

  .filter-group {
    font-family: Acumin Pro Wide;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    display: grid;
    grid-template-columns: 1fr;
    /* Single column layout for labels and selects */
    gap: 5px;
    /* Space between label and select */

  }

  .filter-group label {
    text-align: left;
    /* Align text to the left */
  }

  .filter-group select {
    width: 100%;
    /* Make dropdowns take full width of their cell */
    box-sizing: border-box;
    /* Include padding and border in the width calculation */
  }

  .export-button {
    font-family: Acumin Pro Wide;
    font-size: 18px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    display: block;
    width: 100%;
    /* Make the export button take the full width of its container */
    max-width: 100px;
    /* Optional: Set a max-width to avoid too wide buttons */
    margin: 10px auto;
    /* Center the button and ensure it has margin */
    text-align: center;
    /* Center text within button */
  }

  .counters-container {
    font-family: Acumin Pro Wide;
    font-size: 26px;
    font-style: italic;
    font-weight: bold;
    line-height: 115%;
    letter-spacing: -0.65px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    margin-bottom: 20px;
    font-size: 14px;
    color: #803BF7;
  }

  .searchstyle {
    font-size: 18px;
    width: 10px;
    color: white;
  }
}

@media (min-width: 768px) {
  .search-container {
    flex-direction: row;
    justify-content: center;
    gap: 10px;
  }

  .filters-container {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    /* Center items horizontally in desktop view */
    gap: 20px;
    margin-top: 20px;
    /* Adjust space between filter groups */
  }

  .filter-group {
    gap: 5px;
    /* Space between label and select */
  }

  .counters-container {
    flex-direction: row;
    justify-content: center;
    gap: 12px;
  }
}
</style>