<template>
  <div>
    <div class="registration-form">
      <h3 :class="formData.isGuest != null ? 'mainTitle' : 'mainTitleClicked'">PRAYER WALL</h3>
      <h5>LOVE SOULS RELENTLESSLY</h5>
      <form @keydown.enter.prevent @submit.prevent="submitForm" class="form-container">
        <div class="count-display">
          <!--<span v-if="guestCGMembersInvitedCount >= 50">{{ guestCGMembersInvitedCount }} Successful Invites
            /&nbsp;</span>-->
          <span v-if="guestCGMembersCount != 0">{{ new Intl.NumberFormat().format(guestCGMembersCount) }} names we are praying for</span>

        </div>

        <!-- User Type Selection -->
        <div class="form-groupradio">
          <div class='radio-wrapper'>
            <span class="radio-mainlabel">I am</span>
            <span class="radio-group">
              <label class="radio-label">
                <input type="radio" v-model="formData.isGuest" :value="false" required />
                In a CG/MG
              </label>
              <label class="radio-label">
                <input type="radio" v-model="formData.isGuest" :value="true" required />
                Not in a CG
              </label>
            </span>
          </div>
          <p v-if="formData.isGuest === null && isFormSubmitted" class="error-message">Please select an option.</p>
        </div>

        <!--<div v-if="guestCGMembersCount != 0 && formData.isGuest == null" class="count-displayFirst">
          <span v-if="guestCGMembersInvitedCount >= 50">{{ guestCGMembersInvitedCount }} Successful Invites
            /&nbsp;</span>
          <span>{{ new Intl.NumberFormat().format(guestCGMembersCount) }} names we are praying for</span>

        </div>-->

        <!-- Area/Ministry Dropdown (Only for Non-Guests) -->
        <div v-if="!formData.isGuest && formData.isGuest != null" class="form-group">
          <div class="flex-container">
            <label for="dropdown">Area/Ministry</label>
            <select id="dropdown" v-model="formData.selectedOptionArea" @change="fetchCGGroups" required>
              <option v-for="item in areaDropDownList" :key="item.id" :value="item.AreaMinistryID">
                {{ item.AreaMinistryName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Connect Group Dropdown (Only for Non-Guests) -->
        <div v-if="!formData.isGuest && formData.selectedOptionArea" class="form-group">
          <div class="flex-container">
            <label for="cg-group">Connect Group</label>
            <select id="cg-group" v-model="formData.selectedOption" required>
              <option v-for="item in cgDropDownList" :key="item.id" :value="item.CGID">
                {{ item.CGName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Centre Dropdown (Only for Guests) -->
        <div v-if="formData.isGuest" class="form-group">
          <div class="flex-container">
            <label for="dropdown">I attend</label>
            <select id="dropdown" v-model="formData.selectedCentre" required>
              <option v-for="item in centreOptions" :key="item.CentreID" :value="item.CentreID">
                {{ item.CentreName }}
              </option>
            </select>
          </div>
        </div>

        <!-- Name Input Field (Always Required) -->
        <div v-if="formData.isGuest != null" class="form-group">
          <div class="flex-container">
            <label for="inviter-name">My Name</label>
            <input v-model="formData.inviterName" type="text" id="inviter-name" required />
          </div>
        </div>

        <div v-if="formData.isGuest != null" class="form-group">
          <label for="friend-names">My family's/friend's names</label>
          <div class="friend-names-container">
            <!-- Display added friend names as horizontal "pills" -->
            <span v-for="(friend, index) in formData.friendNames" :key="index" class="friend-tag">
              {{ friend }}
              <button @click="removeFriend(index)" type="button" class="remove-friend-button">X</button>
            </span>

            <!-- Conditional "+" button to show the input field -->
            <button v-if="!showInput && formData.friendNames.length > 0 && formData.friendNames.length < 10"
              @click="showInput = true" type="button" class="show-input-button">+</button>

          </div>

          <!-- Input for adding a new friend -->
          <div v-if="(showInput || formData.friendNames.length == 0) && formData.friendNames.length < 10"
            class="friend-input">
            <input v-model="newFriendName" type="text" placeholder=" Add a name" @keyup.enter="addFriend"
              maxlength="20" />
            <button @click="addFriend" type="button" class="add-friend-button">+</button>
          </div>

        </div>

        <!-- Submit Button -->
        <button v-if="formData.isGuest != null" type="submit"
          class="submit-button">Submit</button>

      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'RegistrationForm',
  data() {
    return {
      formData: {
        isGuest: null, // Determines if the user is a guest
        selectedOption: null,
        selectedOptionArea: null,
        selectedCentre: null,
        fullName: '',
        inviterName: '',
        friendNames: []
      },
      cgDropDownList: [],
      areaDropDownList: [],
      centreOptions: [],
      isFormSubmitted: false, // Flag to track if form has been submitted
      guestCGMembersCount: 0, // Add this property to store the count
      guestCGMembersInvitedCount: 0,
      newFriendName: '',
      showInput: false // Add this line
    };
  },
  mounted() {
    this.fetchAreas();
    this.fetchCentres();
    this.fetchGuestCGMembersCount();
    this.fetchGuestCGMembersInvitedCount();
  },
  methods: {
    addFriend() {
      if (this.newFriendName.trim() && this.formData.friendNames.length < 10) {
        this.formData.friendNames.push(this.newFriendName.trim());
        this.newFriendName = ''; // Clear input field after adding
        this.showInput = false; // Hide input after adding
      }
    },
    removeFriend(index) {
      this.formData.friendNames.splice(index, 1); // Remove the friend by index
    },
    async fetchAreas() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/areaministry');
        this.areaDropDownList = response.data;
      } catch (error) {
        console.error('Error fetching areas:', error);
      }
    },
    async fetchCGGroups() {
      try {
        const areaID = this.formData.selectedOptionArea;
        if (areaID) {
          const response = await axios.get(`https://prayerwall.trinity.sg/api/cggroups/${areaID}`);
          this.cgDropDownList = response.data;
        } else {
          this.cgDropDownList = []; // Clear CG groups if no area is selected
        }
      } catch (error) {
        console.error('Error fetching CG groups:', error);
      }
    },
    async fetchCentres() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/centres');
        this.centreOptions = response.data;
      } catch (error) {
        console.error('Error fetching centres:', error);
      }
    },
    async fetchGuestCGMembersCount() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/guestcgmembers/count');
        this.guestCGMembersCount = response.data.count;
      } catch (error) {
        console.error('Error fetching guest CG members count:', error);
      }
    },
    async fetchGuestCGMembersInvitedCount() {
      try {
        const response = await axios.get('https://prayerwall.trinity.sg/api/guestcgmembers/countinvites');
        this.guestCGMembersInvitedCount = response.data.count;
      } catch (error) {
        console.error('Error fetching guest CG members invite count:', error);
      }
    },
    async submitForm() {
      this.isFormSubmitted = true; // Set flag to true on form submission

      // Check if isGuest is null or undefined
      if (this.formData.isGuest === null || this.formData.isGuest === undefined) {
        return; // Do not submit if isGuest is not selected
      }
      if(!this.newFriendName.trim() && this.formData.friendNames.length == 0){
        return;
      }

      if (this.newFriendName.trim()){
        this.formData.friendNames.push(this.newFriendName.trim())
      }

      // Define data based on user type
      let data = {};
      const url = this.formData.isGuest
        ? 'https://prayerwall.trinity.sg/api/guestregister'
        : 'https://prayerwall.trinity.sg/api/register';

      if (this.formData.isGuest) {
        data = {
          selectedCentre: this.formData.selectedCentre,
          friendNames: this.formData.friendNames,
          inviterName: this.formData.inviterName
        };
      } else {
        data = {
          selectedAreaMinistry: this.formData.selectedOptionArea,
          selectedCGgroup: this.formData.selectedOption,
          friendNames: this.formData.friendNames,
          inviterName: this.formData.inviterName
        };
      }

      try {
        const response = await axios.post(url, data);
        this.$router.push('/success');
        console.log(response.data);
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Registration failed.');
      }
    },
  },
};
</script>

<style scoped>
.background-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Ensure container covers at least the full viewport height */
  background-image: url('../assets/background.png');
  /* Adjust path as needed */
  background-size: cover;
  /* Stretch the image to cover the entire container */
  background-position: center;
  /* Center the image */
  background-repeat: no-repeat;
  /* Prevent image repetition */
}

.content {
  flex: 1;
  /* Allow content to grow and push other content down if needed */
}

.show-input-button {
  padding: 5px;
  /* Adjust padding for better control */
  /* Font size for the "+" */
  width: 26px;
  /* Set a fixed width */
  height: 26px;
  /* Set a fixed height to prevent stretching */
  border: none;
  /* Remove border (optional) */
  border-radius: 20px;
  /* Make the button circular */
  background-color: #803BF7;
  /* Example background color */
  color: white;
  /* Text color */
  cursor: pointer;
  /* Pointer cursor on hover */
  display: inline-flex;
  /* Align button content horizontally and vertically */
  justify-content: center;
  /* Center the "+" horizontally */
  align-items: center;
  /* Center the "+" vertically */
}

/* Display the added friend names as horizontal "pills" */
.friend-names-container {

  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  align-items: left;
  /* Add some space at the bottom if necessary */

}

/* Each friend tag style */
.friend-tag {
  background-color: #A0F2C9;
  border-radius: 20px;
  padding: 5px 40px 5px 10px;
  /* Extra padding on the right to accommodate the button */
  display: inline-flex;
  align-items: center;
  font-weight: bold;
  font-family: 'Acumin Pro Wide';
  color: #803BF7;
  margin-bottom: 6px;
  position: relative;
  /* Makes the remove button positionable inside */
}

/* Button to remove a friend (inside the tag) */
.remove-friend-button {
  background: none;
  border: none;
  color: #803BF7;
  /* Red for the 'X' button */
  font-size: 12.8px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  /* Aligns the button to the right inside the "pill" */
  top: 50%;
  transform: translateY(-50%);
  /* Centers the button vertically */
}

.friend-input {
  display: flex;
  gap: 5px;
  align-items: center;
  margin-top: -10px;
  /* Center align items vertically */
}

.friend-input input {
  flex: 1;
  /* Take up the remaining space */
  background-color: #A0F2C9;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.add-friend-button {
  background-color: #803BF7;
  color: white;
  border: none;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  border-radius: 15px;
  cursor: pointer;
}

@font-face {
  font-family: 'Acumin Pro Wide';
  src: url('../assets/Acumin Pro Wide Book.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Kalista Serif';
  src: url('../assets/KalistaSerif-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* Basic reset for form elements */
form {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container for the form */
.registration-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.count-display {
  margin-top: 12px;
  font-family: Acumin Pro Wide;
  font-size: 80%;
  font-style: italic;
  font-weight: medium;
  line-height: 120%;
  color: #803BF7;
}

.count-displayFirst {
  margin-top: -12px;
  font-family: Acumin Pro Wide;
  font-size: 80%;
  font-style: italic;
  font-weight: medium;
  line-height: 120%;
  color: #803BF7;
}

.mainTitle {
  text-align: center;
  color: #803BF7;
  font-family: Acumin Pro Wide;
  font-size: 300%;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 4px;
  margin-bottom: 8px;
  margin-top: 15%;
}

.mainTitleClicked {
  text-align: center;
  color: #803BF7;
  font-family: Acumin Pro Wide;
  font-size: 300%;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 4px;
  margin-bottom: 8px;
  margin-top: 25%;
}

/* Title styling */
h5 {
  text-align: center;
  color: #37008A;
  margin-bottom: -10px;
  margin-top: 4px;
  font-family: Kalista Serif;
  font-size: 120%;
  font-style: normal;
  font-weight: bold;
  line-height: 160%;
  letter-spacing: 4px;
}

/* Flex container for the form fields */
.form-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Style for each form group */
.form-group {
  font-family: 'Acumin Pro Wide';
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 80%;
  color: #803BF7;
}

.form-groupradio {
  font-family: 'Acumin Pro Wide';
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 100%;
  color: #803BF7;
}

/* Align labels to the far left */
.form-group label {
  font-family: 'Acumin Pro Wide';
  margin: 0;
  text-align: left;
  display: block;
  color: #803BF7;
  font-weight: bold;
  /* Ensures label takes full width */
}

/* Styling for labels and input elements */
input[type="text"],
select {
  font-family: 'Acumin Pro Wide';
  font-weight: bold;
  padding: 5px;
  font-size: 100%;
  border: 1px solid #ddd;
  border-radius: 15px;
  box-sizing: border-box;
  width: 80%;
  color: black;
  /* Ensure input fields take full width */
}

/* Style for the dropdowns */
select {
  width: 80%;
}

/* Style for the radio options wrapper */
.radio-wrapperFirst {
  display: flex;
  align-items: center;
  gap: 150px;
  margin-top: 50px;
  font-size: 100%;
}

.radio-wrapper {
  display: flex;
  align-items: center;
  gap: 150px;
  font-size: 100%;
}

/* Flex container for the radio buttons and labels */
.radio-group {
  display: flex;
  gap: 20px;
}

/* Style for radio buttons and labels */
.radio-label {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.radio-mainlabel {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.radio-label input[type="radio"] {
  margin-right: 5px;
}

/* Style for the submit button */
.submit-button {
  font-family: Acumin Pro Wide;
  font-size: 80%;
  font-weight: bold;
  background-color: #A0F2C9;
  color: #803BF7;
  border: none;
  padding: 5px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 20%;
  /* Adjust width as needed or use a percentage */
  margin: 0 auto;
  /* Center the button horizontally */
  display: inline-flex;
  /* Use flexbox layout */
  justify-content: center;
  /* Center text horizontally */
  align-items: center;
  /* Center text vertically */

}

/* Style for error message */
.error-message {
  color: white;
  font-size: 14px;
}

/* Style for the button group */
.button-group {
  display: flex;
  gap: 5px;
}

/* Ensure label and select are aligned on the same line */
.flex-container {
  display: flex;
  align-items: center;
  /* Center items vertically */
  gap: 10px;
  margin-bottom: -4px;
  /* Optional: Adjust spacing between label and select */
}

/* Style for labels */
.flex-container label {
  font-family: 'Acumin Pro Wide';
  font-weight: bold;
  color: #803BF7;
  margin-right: 1%;
  width: 110px;
  /* Adjust width as needed */
  text-align: left;
}

/* Style for select element */
.flex-container select {
  font-family: 'Acumin Pro Wide';
  flex: 1;
  /* Allow select to take remaining space */
  padding: 5px;
  font-weight: bold;
  border: 1px solid #A0F2C9;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: #A0F2C9;
}

.flex-container input {
  font-family: 'Acumin Pro Wide';
  flex: 1;
  /* Allow select to take remaining space */
  padding: 5px;
  border: 1px solid #A0F2C9;
  border-radius: 15px;
  box-sizing: border-box;
  background-color: #A0F2C9;
}

@media (min-height: 700px) {
  .mainTitle {
    margin-top: 30%;
  }

  .mainTitleClicked {
    margin-top: 30%;
  }
}

/* Mobile responsive adjustments */
@media (max-width: 767px) {
  .mainTitle {
    text-align: center;
    color: #803BF7;
    font-family: Acumin Pro Wide;
    font-size: 34px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    letter-spacing: 4px;
    margin-bottom: 8px;
    margin-top: 30%;
  }

  .mainTitleClicked {
    text-align: center;
    color: #803BF7;
    font-family: Acumin Pro Wide;
    font-size: 34px;
    font-style: normal;
    font-weight: bold;
    line-height: 120%;
    letter-spacing: 4px;
    margin-bottom: 8px;
    margin-top: 30%;
  }

  h5 {
    font-family: Kalista Serif;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 160%;
    letter-spacing: 2px;
  }

  .count-display {
    font-family: Acumin Pro Wide;
    font-size: 16px;
    font-style: italic;
    font-weight: medium;
    line-height: 120%;
  }

  .count-displayFirst {
    font-family: Acumin Pro Wide;
    margin-top: -12px;
    font-size: 16px;
    font-style: italic;
    font-weight: medium;
    line-height: 120%;
  }

  .form-group {
    font-size: 16px;
    justify-content: center;
  }

  .submit-button {
    font-size: 13px;
    padding: 10px;
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .radio-wrapperFirst {
    display: flex;
    align-items: center;
    gap: 50px;
  }

  .radio-label {
    font-size: 16px;
  }

  @media (max-width: 380px) {
    .mainTitle {
    letter-spacing: 1px;
  }

  .mainTitleClicked {
    letter-spacing: 1px;
    
  }

  h5 {
    letter-spacing: 1px;
  }

    .remove-friend-button {
      right: 4px;
      /* Aligns the button to the right inside the "pill" */

    }

    .flex-container label {
      margin-right: 6%;
    }

    .radio-wrapper {
      display: flex;
      align-items: center;
      gap: 30px;
    }

    .radio-wrapperFirst {
      display: flex;
      align-items: center;
      gap: 30px;
    }
  }

}
</style>